/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './_ens/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_ens/_assets/plugins/flaticon/flaticon.css';
import './_ens/_assets/plugins/flaticon2/flaticon.css';

// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { EnsLayoutProvider, EnsSplashScreenProvider, EnsSubheaderProvider } from './_ens/layout';
import { EnsI18nProvider } from './_ens/i18n';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

const { PUBLIC_URL } = process.env;

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('../firebase-messaging-sw.js')
//     .then(function(registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function(err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }

ReactDOM.render(
  <EnsI18nProvider>
    <EnsLayoutProvider>
      <EnsSubheaderProvider>
        <EnsSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </EnsSplashScreenProvider>
      </EnsSubheaderProvider>
    </EnsLayoutProvider>
  </EnsI18nProvider>,
  document.getElementById('root')
);
