import { Repository } from '../../servics/httpsServics';

const controller = '/accounts';
const availability = '/availability';
const callAvailability = '/call-availability';
const appointments = '/appointments';
const appointmentStatus = '/appointment-status';
const callAppointments = '/call-appointments';
const affiliatedstructure = '/affiliatedstructure';
const userCall = '/user-calls';

export function getUserListWithFilterPagination(role, data) {
  return Repository.get(controller + '/list_user' + '?role=' + role, { params: data });
}

export function getUserList(role, includeDeleted = false) {
  return Repository.get(`${controller}?role=${role}&includeDeleted=${includeDeleted}`);
}

// for inactive user document get
export function getUserDocument(id) {
  return Repository.get(controller + '/get-document', {
    params: { id: id }
  });
}

export function deleteUser(id) {
  return Repository.delete(controller + '/' + id);
}

export function createUser(data) {
  return Repository.post(controller, data);
}

export function updateUser(data, id) {
  return Repository.put(controller + '/' + id, data);
}

export function anonymizeUser(data, id) {
  return Repository.post(controller + '/anonymize-user/' + id, data);
}

export function getCalculationForInterpreter(data) {
  return Repository.get(controller + '/get-all-calculation', {
    params: data
  });
}
export function toggleCallAvailabilityVisibility(id) {
  return Repository.put(`${callAvailability}/hide/${id}`);
}
export function toggleAppointmentVisibility(id) {
  return Repository.put(`${appointments}/hide/${id}`);
}
export function toggleUserCallVisibility(id) {
  return Repository.put(`${userCall}/hide/${id}`);
}

export function Availbility(data) {
  return Repository.post(availability, data);
}

export function CallAvailbility(data) {
  return Repository.post(callAvailability, data);
}

export function getAvailbility() {
  return Repository.get(availability);
}

export function getCallAvailbility() {
  return Repository.get(callAvailability);
}

export function getUserAppointmentById(id) {
  return Repository.get(appointments + '/' + id);
}
export function getAppointmentStatusById(id) {
  return Repository.get(appointments + '/details/' + id);
}

export function getInterpreterRequestedAppt(id) {
  return Repository.get(appointments + '/pending/appt/' + id);
}
export function deleteAppointment(id) {
  return Repository.delete(appointments + '/' + id);
}

export function getAllInterprator() {
  return Repository.get(availability + '/all');
}

export function getAllInterpratorCall() {
  return Repository.get(callAvailability + '/all');
}

export function getInterpratorAppointmentById(id) {
  return Repository.get(appointments + '/interepreter/' + id);
}

export function getInterpratorAvailbilityById(id) {
  return Repository.get(availability + '/' + id);
}

export function getInterpratorCallAvailbilityById(id) {
  return Repository.get(callAvailability + '/' + id);
}

export function getUserAppointment() {
  return Repository.get(appointments);
}

export function MakeAppointment(data, video) {
  if (video) return Repository.post(callAppointments, data);
  else return Repository.post(appointments, data);
}

export function modifyAppointment(data) {
  return Repository.put(appointments, data);
}

export function modifyavailbility(data) {
  return Repository.put(availability, data);
}

export function deleteAvailbilities(data) {
  return Repository.put(availability + '/delete', data);
}

export function modifyCallAvailbility(data) {
  return Repository.put(callAvailability, data);
}

export function deleteCallAvailbilities(data) {
  return Repository.put(callAvailability + '/delete', data);
}

export function getpendingReqs() {
  return Repository.get(appointments + '/pending/appts');
}

export function getMsgCount(data) {
  return Repository.get('/admin-chat/get-msg-count', {
    params: data
  });
}

export function getPendingList(data) {
  return Repository.get(appointments + '/pending/appts/list', { params: data });
}

export function assignAppointment(data) {
  return Repository.put(appointments + '/assign', data);
}

export function updateAppointment(data) {
  return Repository.put(appointments + '/reply', data);
}

export function getAdminSettings() {
  return Repository.get('/setting');
}

export function getAminChatIds() {
  return Repository.get('/setting/get-setting-admin-chat');
}

export function updateAdminSettings(data) {
  return Repository.put('/setting', data);
}

export function getUpdateTimeAppointmentList(data) {
  return Repository.get(appointments + '/get-update-time-appointments-admin', { params: data });
}

export function getUpdateChangeDataForAdmin(data) {
  return Repository.get(appointments + `/get-update-time-appointments-admin/${data}`);
}

export function updateAppointmentUpdateDataAdmin(data) {
  return Repository.post(appointments + '/update-admin-time-appointments', data);
}

export function allInterpreterMailForAppointment(data) {
  return Repository.get(appointments + `/all-interpreter-mail-appointment/${data}`);
}

export function hm2dec(hoursMinutes) {
  var hours = parseInt(hoursMinutes[0], 10);
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return (hours + minutes / 60).toFixed(2);
}

export function getAffiliatedStructureListWithFilterPagination(data) {
  return Repository.get(affiliatedstructure + '/list_affiliated_structures', { params: data });
}

export function getAffiliatedStructureList() {
  return Repository.get(affiliatedstructure + '/all');
}

export function deleteAffiliatedStructure(id) {
  return Repository.delete(affiliatedstructure + '/' + id);
}

export function createAffiliatedStructure(data) {
  return Repository.post(affiliatedstructure, data);
}

export function updateAffiliatedStructure(data, id) {
  return Repository.put(affiliatedstructure + '/' + id, data);
}

export function updateAppointmentStatus(data) {
  return Repository.put(appointmentStatus, data);
}

export function getInterpreterAppointments(data) {
  return Repository.get(appointmentStatus, { params: data });
}
