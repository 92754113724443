import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import DeleteModal from '../../../_ens/layout/components/Confirmation';
import PositionedModal from '../../../_ens/layout/components/modal/modal';
import { APILINK } from '../../../config/config';
import * as common from '../../../redux/commonReducer';
import {
  getAdminSettings,
  getAppointmentStatusById,
  updateAppointment
} from '../../modules/Admin/AdminActions';
import { isAdmin, isInterpreter } from '../../servics/CommonFunctions';
import apptStatus from '../../../_ens/_helpers/constants';

function UserPopup(props) {
  const { intl } = props;
  const [geoRule, setGeoRule] = useState(false);
  const [noEnte, setNoEnte] = useState(false);
  const [videoAppointment, setVideoAppointment] = useState(!!props.appointmentModel.videoCode);
  const initialValues = props.appointmentModel;
  const [affiliatedOption] = useState(props.appointmentModel.affiliatedOption);
  const [aptStatus, setAptStatus] = useState(
    intl.formatMessage({
      id: 'CONFIRMATION'
    })
  );
  const appointmentDateTime = new Date(
    props.appointmentModel.final_date + 'T' + props.appointmentModel.final_end_time
  );
  const now = new Date();
  let history = useHistory();

  const onCallInterpreter = (videoCode) => {
    history.push('singlecall/', videoCode);
  };

  useEffect(() => {
    if (isInterpreter()) {
      //console.log(props.appointmentModel);
      getAppointmentStatusById(props.appointmentModel.id)
        .then((res) => {
          if (
            res.data.status === apptStatus.DeclinedByInterpreter ||
            res.data.status === apptStatus.DeclinedBySystem
          ) {
            props.commonActions.showSnackBar(true, 'Non sei Autorizzato!.', 'error');
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        })
        .catch((error) => {
          console.error("Errore durante il recupero dello stato dell'appuntamento:", error);
        });
    }

    props.commonActions.openModal(true);
    getAdminSettings().then((res) => {
      setGeoRule(res.data.geoRule);
      setNoEnte(res.data.noEnte);
    });

    if (props.appointmentModel.status === 1) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.ACCEPTED'
      });
      if (!!props.appointmentModel.videoCode) string += ' | VIDEOCHIAMATA';
      setAptStatus(
        intl.formatMessage({
          id: 'CONFIRMATION'
        }) +
          ' | ' +
          string
      );
    }

    if (props.appointmentModel.status === 2) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.PENDINGACCEPTANCE'
      });
      if (!!props.appointmentModel.videoCode) string += ' | VIDEOCHIAMATA';
      setAptStatus(
        intl.formatMessage({
          id: 'CONFIRMATION'
        }) +
          ' | ' +
          string
      );
    }
  }, [props.appointmentModel]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const AppointmentSchema = Yup.object().shape({
    interpreterId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /* if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const downlodaFile = async (appointment_file) => {
    fetch(APILINK + appointment_file, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${props.auth.authToken}`
      }
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = appointment_file.replace('/appointment/', '');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
    }
  });

  const handleRequest = (val) => {
    let datatosend = {
      reply: val,
      statusId: props.appointmentModel.id,
      appointmentId:
        props.appointmentModel.appointment && props.appointmentModel.appointment.id
          ? props.appointmentModel.appointment.id
          : ''
    };

    updateAppointment(datatosend)
      .then(() => {
        props.commonActions.showSnackBar(
          true,
          intl.formatMessage({
            id: 'DASHBOARD.INTERPRATOR_UPDATE_APPOINTMENT'
          })
        );
        props.onModelHide();
        if (props.updateCurrentUser) props.updateCurrentUser();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          props.commonActions.showSnackBar(true, error.response.data.message, 'error');
        } else {
          props.commonActions.showSnackBar(true, 'Errore Generico. Riprovare!', 'error');
        }
      });
  };

  //props.onModelHide

  return (
    <div>
      <DeleteModal
        open={confirmDelete}
        title={intl.formatMessage({
          id: 'CONFIRMATION'
        })}
        msg={intl.formatMessage({
          id: 'APPOINTMENT_DECLINE'
        })}
        handleCancel={() => setConfirmDelete(false)}
        cacelColor="btn btn-light btn-elevate"
        cancelText={intl.formatMessage({
          id: 'No'
        })}
        handleOk={() => handleRequest(3)}
        okColor="btn btn-delete btn-elevate"
        okText={intl.formatMessage({
          id: 'YES'
        })}
      />

      <PositionedModal
        center={true}
        show={props.modalShow}
        title={aptStatus}
        onHide={() => {
          props.onModelHide();
          props.commonActions.openModal(false);
        }}
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        {...(appointmentDateTime > now && {
          onSubmit: () => handleRequest(1),
          updateText:
            props.appointmentModel.status !== 1
              ? intl.formatMessage({
                  id: 'ACCEPT'
                })
              : '',
          deletebtn: () => setConfirmDelete(true),
          deletetext:
            props.appointmentModel.isdeleted !== 1
              ? intl.formatMessage({
                  id: 'DECLINE'
                })
              : ''
        })}
        deleteClass="btn btn-delete btn-elevate"
      >
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group row">
            {/* First Name */}
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DATE" />
              </label>
              <input
                readOnly={props.appointmentModel.id}
                placeholder="Date"
                type="date"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'date'
                )}`}
                name="date"
                {...formik.getFieldProps('final_date')}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_TIME" />
              </label>
              <input
                placeholder="Start Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'startTime'
                )}`}
                name="startTime"
                readOnly={props.appointmentModel.id}
                {...formik.getFieldProps('startTime')}
              />
              {formik.touched.startTime && formik.errors.startTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startTime}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.END_TIME" />
              </label>
              <input
                placeholder="End Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'endTime'
                )}`}
                name="endTime"
                readOnly={props.appointmentModel.id}
                {...formik.getFieldProps('endTime')}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.TITLE" />
              </label>
              <input
                placeholder="Title"
                type="text"
                readOnly={props.appointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'title'
                )}`}
                name="title"
                {...formik.getFieldProps('title')}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </div>
          </div>
          {!videoAppointment ? (
            <>
              {!geoRule ? (
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label className="col-form-label">
                      <FormattedMessage id="DASHBOARD.ADDRESS" />
                    </label>

                    <input
                      placeholder="Address"
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        'place'
                      )}`}
                      readOnly={props.appointmentModel.id}
                      name="place"
                      {...formik.getFieldProps('place')}
                    />
                  </div>
                </div>
              ) : (
                <div className={'form-group row ' + (noEnte ? 'display-none' : '')}>
                  <div className="col-lg-12">
                    <label className="col-form-label">
                      <FormattedMessage id="DASHBOARD.AFFILIATED" />
                    </label>

                    <Select
                      readonly
                      options={affiliatedOption}
                      name="affiliatedstructureId"
                      value={affiliatedOption.filter(
                        (data) => data.value === formik.values.affiliatedstructureId
                      )}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        'affiliatedstructureId'
                      )}`}
                      onChange={(e) => formik.setFieldValue(`affiliatedstructureId`, e.value)}
                      isSearchable
                    />
                  </div>
                </div>
              )}
            </>
          ) : null}

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DESCRIPTION" />
              </label>
              <textarea
                placeholder="description"
                rows="4"
                readOnly={props.appointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'description'
                )}`}
                name="description"
                {...formik.getFieldProps('description')}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.description}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.CUSTOMERNAME" />
              </label>
              <input
                placeholder="customerName"
                type="text"
                readOnly
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'customerName'
                )}`}
                name="customerName"
                value={
                  props.appointmentModel.account.firstName +
                  ' ' +
                  props.appointmentModel.account.lastName
                }
              />
            </div>
            {props?.appointmentModel?.id && props.appointmentModel.appointment_file !== null ? (
              <div className="col-lg-6">
                <label className="col-form-label mr-5">
                  <FormattedMessage id="APPOINTMENT_FILE" />
                </label>
                <span className="d-block">
                  <a
                    onClick={() =>
                      downlodaFile(props.appointmentModel.appointment_file, props.appointmentModel)
                    }
                  >
                    <FormattedMessage id="PROFILE.VIE_PROOF" />{' '}
                  </a>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>

          {!isAdmin() &&
          props.appointmentModel.id &&
          props.appointmentModel.videoCode !== '' &&
          props.appointmentModel.videoCode !== null &&
          props.appointmentModel.status === 1 &&
          moment(props.appointmentModel.final_date).isSame(moment(), 'day') ? (
            <div className="form-group row">
              <div className="col-lg-12 text-center">
                <Button
                  variant="danger"
                  className="fnt-videocall"
                  onClick={() => onCallInterpreter(props.appointmentModel.videoCode)}
                >
                  AVVIA VIDEOCHIAMATA
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </form>
      </PositionedModal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(UserPopup));
