import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAppointmentToReject, updateRejectedAppointment } from './action';
function RejectAppointment(props) {
  const [appointmentData, setAppointmentData] = useState();
  const [status, setStatus] = useState({
    loading: true,
    error: null,
    success: null
  });

  useEffect(() => {
    const fetchAndRejectAppointment = async () => {
      try {
        if (!props.match.params || !props.match.params.token || !props.match.params.email) {
          setStatus((prev) => ({
            ...prev,
            loading: false,
            error: 'ID appuntamento non valido.'
          }));
          return;
        }

        const res = await getAppointmentToReject({
          token: props.match.params.token,
          email: props.match.params.email
        });
        const details = res.data;
        setAppointmentData({ ...res.data.appointment });

        if (!details) {
          setStatus((prev) => ({
            ...prev,
            loading: false,
            error: 'Appuntamento non trovato.'
          }));
          return;
        }

        if (details.status === 3) {
          // Already rejected
          setStatus((prev) => ({
            ...prev,
            loading: false,
            success: "L'appuntamento è già stato rifiutato.",
            appointmentDetails: details
          }));
          return;
        }

        // If not already rejected, attempt to reject it
        await updateRejectedAppointment(
          {
            token: props.match.params.token,
            email: props.match.params.email
          },
          {
            reply: 3,
            appointmentId: details.appointment.id,
            statusId: details.id
          }
        );

        // On success
        setStatus((prev) => ({
          ...prev,
          loading: false,
          success: 'Appuntamento rifiutato con successo.',
          appointmentDetails: details
        }));
      } catch (error) {
        setStatus((prev) => ({
          ...prev,
          loading: false,
          error:
            error.response && error.response.status === 404
              ? 'Appuntamento non trovato.'
              : "Non è possibile rifiutare l'appuntamento"
        }));
      }
    };

    fetchAndRejectAppointment();
  }, [props.match.params]);

  const { loading, error, success } = status;

  if (loading) {
    return (
      <div className="d-flex justify-content-center p-5">
        <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        <span className="sr-only">Caricamento in corso...</span>
      </div>
    );
  }

  return (
    <div className="login-form login-signin update_appointment" id="kt_login_signin_form">
      <div className="text-center mb-5">
        <h3 className="font-size-h1">Rifiuta Appuntamento</h3>
      </div>

      {appointmentData && (
        <div className="view_appointment_data">
          <div className="row">
            <div className="col-6 view_appointment_data_item title">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.TITLE" />
            </div>
            <div className="col-6 view_appointment_data_item title">{appointmentData.title}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.DESCRIPTION" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.description}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.PLACE" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.place}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.DATE" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.final_date}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.START.TIME" />
            </div>
            <div className="col-6 view_appointment_data_item">
              {appointmentData.final_start_time}
            </div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.END.TIME" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.final_end_time}</div>
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger mb-4" role="alert">
          {error}
        </div>
      )}

      {success && (
        <div className="alert alert-success mb-4" role="alert">
          {success}
        </div>
      )}
    </div>
  );
}

export default injectIntl(RejectAppointment);
