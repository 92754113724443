import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
  AllPermissions,
  checkUserPermision,
  isAdmin,
  isAdminVerified,
  isInterpreter,
  isUser
} from '../servics/CommonFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  getAdminSettings,
  getAffiliatedStructureList,
  getAllInterprator,
  getAvailbility,
  getInterpratorAppointmentById,
  getInterpratorAvailbilityById,
  getUserAppointment,
  getUserAppointmentById,
  getUserList
} from '../modules/Admin/AdminActions';
import SchedulePopup from './Popup/Schedule';
import ApointmentPopup from './Popup/BookApointment';
import AcceptPopup from './Popup/UpdateAppointment';
import { useFormik } from 'formik';
import store from '../../redux/store';
import { toAbsoluteUrl } from '../../_ens/_helpers';
import SVG from 'react-inlinesvg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as common from '../../redux/commonReducer';
import Select from 'react-select';
import { AVVISOSOSPENSIONECALENDAR } from '../../config/config';
import 'moment/locale/it';
import { useLocation } from 'react-router-dom';

moment.locale('it');
const localizer = momentLocalizer(moment);

function DashboardPage(props) {
  const { intl } = props;
  // change for calendar
  const today = new Date();

  let user = store.getState().auth.user;
  const [modalShow, setModalShow] = useState(false);
  const [event, setEvent] = useState([]);
  const [acceptPopup, setacceptPopup] = useState(false);
  const [interprator, setInterprator] = useState([]);
  const [users, setusers] = useState([]);
  const [adminType, setAdminType] = useState(0);
  const [currentView, setCurrentView] = useState('work_week');
  const [externalaptbtn, setexternalaptbtn] = useState(false);
  const [externalschbtn, setexternalschbtn] = useState(false);
  const [onSiteToggle, setOnSiteToggle] = useState(true);

  const [interpreterOption, setInterpreterOption] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [affiliatedOption, setAffiliatedOption] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const apptsToOpen = queryParams.get('appts');

  const initialValues = {
    userType: '',
    user: '',
    interpratorId: ''
  };

  const getFirstDayOfWeek = () => {
    const tod = new Date();
    const today = new Date(tod.getFullYear(), tod.getMonth(), tod.getDate(), 0);
    const dayOfWeek = today.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() + difference);
    return firstDayOfWeek;
  };

  let availbilityObj = {
    interpreterID: '',
    time: '',
    endTime: '',
    date: '',
    id: '',
    repeatDays: '',
    dateUntil: '',
    day0: false,
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
    day6: false,
    allchecked: false
  };
  let appointmentObj = {
    userId: '',
    startTime: '',
    endTime: '',
    date: '',
    title: '',
    description: '',
    place: '',
    mode: 'inPerson',
    acceptTerms: false
  };

  let userTypes = [
    {
      label: intl.formatMessage({
        id: 'DASHBOARD_SELECT_USER_TYPE'
      }),
      value: '',
      disabled: true
    },
    {
      label: intl.formatMessage({
        id: 'DASHBOARD.USER'
      }),
      value: 1
    },
    {
      label: intl.formatMessage({
        id: 'DASHBOARD.INTERPRATOR'
      }),
      value: 2
    }
  ];

  const [scheduleobj, setscheduleobj] = useState(availbilityObj);
  const [selectedUser, setSelectedUser] = useState(null);
  const [appointmentModel, setAppointment] = useState(appointmentObj);
  const [appModalInt, setAppModalInt] = useState(null);
  const [appointmentList, setAppointmentList] = useState(null);
  const [showAppInt, setShowAppInt] = useState(false);
  let mixedArray = [];

  useEffect(() => {
    getAdminSettings().then(
      (res) => {
        setOnSiteToggle(res.data.onsiteAppointmentStatus);
      },
      () => {}
    );

    if (isAdmin()) {
      getUserList('Interpreter').then((res) => {
        let interpreterOption1 = [];
        interpreterOption1.push({
          label: intl.formatMessage({
            id: 'DASHBOARD_SELECT_INTERPRATOR'
          }),
          value: ''
        });
        res.data.map((e) =>
          interpreterOption1.push({ label: `${e.firstName + ' ' + e.lastName}`, value: e.id })
        );
        setInterpreterOption(interpreterOption1);
        setInterprator(res.data);
      });
      getUserList('User').then((res) => {
        // setusers(res);
        let tempArray = [];
        let userOption1 = [];
        userOption1.push({
          label: intl.formatMessage({
            id: 'DASHBOARD_SELECT_USER'
          }),
          value: ''
        });
        res.data.forEach((user) => {
          if (user.adminVerified === 1 || user.adminVerified === '1') {
            tempArray.push(user);
            userOption1.push({ label: `${user.firstName + ' ' + user.lastName}`, value: user.id });
          }
        });
        setUserOption(userOption1);
        setusers(tempArray);
      });
    }

    getAffiliatedStructureList().then((res) => {
      let affOption1 = [];
      affOption1.push({
        label: intl.formatMessage({
          id: 'DASHBOARD_SELECT_AFFILIATED'
        }),
        value: ''
      });
      res.data.forEach((aff) => {
        affOption1.push({ label: aff.name, value: aff.id });
      });
      setAffiliatedOption(affOption1);
    });
    getIntailData('work_week');
  }, []);

  const getIntailData = (view) => {
    if (isInterpreter()) {
      getAvailbility().then((res) => {
        formatAvailbilityData(res.data);
        getUserAppointment().then((res) => {
          formatInterAppointmentData(res.data);
        });
      });
    }
    if (isUser()) {
      getUserAppointment().then((res) => {
        formatAppointmentData(res.data, view);
        getAllInterprator().then((res) => {
          formatAvailbilityData(res.data, view);
        });
      });
    }

    if (isAdmin()) {
      mixedArray.push([]);
      getAllInterprator().then((res) => {
        formatAvailbilityData(res.data, view);
      });
    }
  };

  const getAdminUserAppointmentById = (id) => {
    event.length = 0;
    setSelectedUser(id);
    setAdminType(1);
    getUserAppointmentById(id).then((res) => {
      mixedArray.push([]);
      formatAppointmentData(res.data);
    });
  };

  const getAdminInterAppointmentById = (id) => {
    event.length = 0;
    setAdminType(2);
    setSelectedUser(id);
    getInterpratorAvailbilityById(id).then((res) => {
      formatAvailbilityData(res.data);
      getInterpratorAppointmentById(id).then((res) => {
        formatInterAppointmentData(res.data);
      });
    });
  };

  // change for column for show final date and final_start_time and final_end_time
  const formatInterAppointmentData = (data) => {
    let tempArray = [];
    data.forEach((key) => {
      let tempobj = {
        start: new Date(key.appointment.final_date + 'T' + key.appointment.final_start_time),
        end: new Date(key.appointment.final_date + 'T' + key.appointment.final_end_time),
        title: key.appointment.title,
        appointmentobj: key.appointment,
        status: key.status,
        type: 'appointment',
        id: key.id
      };
      tempArray.push(tempobj);
    });
    setAppointmentList(tempArray);
    mixedArray.push(tempArray);
    if (mixedArray.length === 2) {
      setEventData(mixedArray[0], mixedArray[1]);
    }
  };

  useEffect(() => {
    if (appointmentList) {
      appointmentList.forEach((app) => {
        if (apptsToOpen && parseInt(apptsToOpen) === app.appointmentobj.id) {
          setAppModalInt({
            ...app.appointmentobj,
            id: app.id,
            status: app.status,
            affiliatedOption: affiliatedOption,
            is_deleted: app.appointmentobj.is_deleted
          });
        }
      });
    }
  }, [appointmentList]);

  useEffect(() => {
    if (!!appModalInt && appModalInt.id) {
      setShowAppInt(true);
    }
  }, [appModalInt]);

  // change for column for show final date and final_start_time and final_end_time
  const formatAppointmentData = (data, view = 'work_week') => {
    let tempArray = [];
    data.forEach((key) => {
      if (key.final_date && key.final_start_time && key.appointmentStatuses.length > 0) {
        let tempobj = {
          start: new Date(key.final_date + 'T' + key.final_start_time),
          end: new Date(key.final_date + 'T' + key.final_end_time),
          title: key.title,
          status: key.appointmentStatuses[0].status,
          appointmentobj: key
        };
        tempArray.push(tempobj);
      }
    });
    mixedArray.push(tempArray);
    if (mixedArray.length === 2) {
      setEventData(mixedArray[0], mixedArray[1], view);
    }
  };

  const setEventData = (array1 = [], array2 = [], view = 'work_week') => {
    let tempArray = [];
    array1.forEach((element) => {
      tempArray.push(element);
    });
    array2.forEach((element) => {
      tempArray.push(element);
    });
    formatdataForMonthView(tempArray, view);
  };

  const getHtml = (view) => {
    return (
      <div className="Interpretor-available">
        {view === 'month' ? (
          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
        ) : (
          ''
        )}
      </div>
    );
  };

  const formatAvailbilityData = (data, view = 'work_week') => {
    let tempArray = [];
    data.forEach((key) => {
      let tempobj = {
        start: new Date(key.date + 'T' + key.time),
        end: new Date(key.date + 'T' + key.endTime),
        title:
          isUser() || isAdmin()
            ? getHtml('work_week')
            : intl.formatMessage({
                id: 'AVAILABLE'
              }),
        type: 'Interprator',
        availbilty: key
      };
      tempArray.push(tempobj);
    });

    mixedArray.push(tempArray);
    if (mixedArray.length === 2) {
      setEventData(mixedArray[0], mixedArray[1], view);
    }
  };

  const formatDate = (date) => {
    let tempDate = new Date(date);
    let month = tempDate.getMonth() + 1;
    if (month < 10) month = '0' + month;

    let currentDate = tempDate.getDate();
    if (currentDate < 10) currentDate = '0' + currentDate;
    return tempDate.getFullYear() + '-' + month + '-' + currentDate;
  };

  const handleSelect = (e) => {
    const showSnackBar = (messageId, type = 'error', duration = 3000) => {
      props.commonActions.showSnackBar(true, intl.formatMessage({ id: messageId }), type, duration);
    };

    const formatTime = (time) => {
      const hours = time
        .getHours()
        .toString()
        .padStart(2, '0');
      const minutes = time
        .getMinutes()
        .toString()
        .padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const isPastDate = (date) => new Date(date) < new Date();

    // Handle past date validation for non-admins
    if (!isAdmin() && currentView !== 'month' && isPastDate(e.start)) {
      showSnackBar('DASHBOARD_PAST_DATE');
      return;
    }

    if (currentView === 'month') {
      const today = new Date();
      const currentDate = new Date(
        `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`
      );
      if (!isAdmin() && isPastDate(e.start)) {
        showSnackBar('DASHBOARD_PAST_DATE');
        return;
      }
    }

    // Handle admin-specific validation
    if ((isAdmin() && adminType === 0) || (isAdmin() && !selectedUser)) {
      showSnackBar('DASHBOARD_USER_NOT_SELECTED');
      return;
    }
    // Format start and end times
    let startTime = '';
    let endTime = '';
    if (e.slots.length > 1) {
      startTime = formatTime(e.slots[0]);
      endTime = formatTime(e.slots[e.slots.length - 1]);
    }

    // Handle appointments and availability
    const formattedDate = formatDate(e.start);
    const commonData = {
      date: formattedDate,
      affiliatedOption,
      startTime,
      endTime
    };

    if (checkUserPermision(AllPermissions.ADDAPOINTMENT) || (isAdmin() && adminType === 1)) {
      setAppointment({
        ...appointmentObj,
        ...commonData,
        userId: isAdmin() ? selectedUser : user.id,
        ...(isAdmin() && { userList: users, interpratorList: interprator, selectedInterprator: '' })
      });
    } else if (
      checkUserPermision(AllPermissions.ADDAVAILBILITY) ||
      (isAdmin() && adminType === 2)
    ) {
      setscheduleobj({
        ...availbilityObj,
        ...commonData,
        interpreterID: isAdmin() ? selectedUser : user.id
      });
    }

    setTimeout(() => setModalShow(true), 200);
  };

  const createappointment = () => {
    setexternalaptbtn(true);
    if (isAdmin()) {
      setAppointment({
        ...appointmentObj,
        userList: users,
        affiliatedOption: affiliatedOption,
        date: formatDate(new Date()),
        userId: '',
        interpratorList: interprator,
        selectedInterprator: '',
        startTime: '',
        endTime: ''
      });
    } else {
      setAppointment({
        ...appointmentObj,
        affiliatedOption: affiliatedOption,
        date: formatDate(new Date()),
        userId: user.id,
        startTime: '',
        endTime: ''
      });
    }

    setTimeout(() => {
      setModalShow(true);
    }, 200);
  };

  const createschedule = () => {
    setexternalschbtn(true);
    setscheduleobj({
      ...availbilityObj,
      date: formatDate(new Date()),
      interpreterID: isAdmin() ? '' : user.id,
      time: '',
      endTime: '',
      interpratorList: interprator
    });
    setTimeout(() => {
      setModalShow(true);
    }, 200);
  };

  // change for final_date and final_start_time and final_end_time
  const onEventSelect = (e) => {
    if (checkUserPermision(AllPermissions.ADDAPOINTMENT) || (isAdmin() && adminType === 1)) {
      if (isAdmin()) {
        setAppointment({
          ...e.appointmentobj,
          date: e.appointmentobj.final_date,
          startTime: e.appointmentobj.final_start_time,
          endTime: e.appointmentobj.final_end_time,
          userList: users,
          affiliatedOption: affiliatedOption,
          interpratorList: interprator,
          selectedInterprator:
            e.appointmentobj.appointmentStatuses[0].interpreterID !== 0
              ? e.appointmentobj.appointmentStatuses[0].interpreterID
              : ''
        });
      } else {
        setAppointment({
          ...e.appointmentobj,
          date: e.appointmentobj.final_date,
          startTime: e.appointmentobj.final_start_time,
          endTime: e.appointmentobj.final_end_time,
          affiliatedOption: affiliatedOption,
          interpratorList: [],
          userList: []
        });
      }
    } else {
      if (e.type === 'appointment') {
        setacceptPopup(true);
        // setAppointment({...e.appointmentobj,startTime:formatTime(e.appointmentobj.startTime),endTime:formatTime(e.appointmentobj.endTime),id:e.id,status:e.status})
        setAppointment({
          ...e.appointmentobj,
          id: e.id,
          status: e.status,
          affiliatedOption: affiliatedOption,
          isdeleted: e.appointmentobj.is_deleted
        });
      } else {
        setacceptPopup(false);
        if (
          isInterpreter() &&
          e.availbilty.online &&
          (compareTime(e.availbilty.time, '8:30') < 0 ||
            compareTime(e.availbilty.endTime, '19:30') > 0)
        ) {
          props.commonActions.showSnackBar(
            true,
            'Il controllo di questa disponibilità online è riservato esclusivamente agli amministratori..',
            'error'
          );
          return;
        }
        setscheduleobj({ ...e.availbilty, reoccurring: 0 });
      }
    }
    setTimeout(() => {
      setModalShow(true);
    }, 200);
  };

  function compareTime(time1, time2) {
    const [hour1, minute1] = time1.split(':').map(Number);
    const [hour2, minute2] = time2.split(':').map(Number);

    if (hour1 === hour2) {
      return minute1 - minute2;
    } else {
      return hour1 - hour2;
    }
  }

  const onModelHide = (view) => {
    setacceptPopup(false);
    setexternalaptbtn(false);
    setexternalschbtn(false);
    mixedArray.length = 0;
    setModalShow(false);

    if (isUser() || isInterpreter()) {
      event.length = 0;
      getIntailData(view);
    }

    if (isAdmin() && selectedUser) {
      if (adminType === 1) {
        getAdminUserAppointmentById(selectedUser);
      } else {
        getAdminInterAppointmentById(selectedUser);
      }
    }
  };

  const formatdataForMonthView = (res, view = 'work_week') => {
    let tempEvents = [];
    res.forEach((element) => {
      if (element.type === 'Interprator') {
        let tempobj = {
          start: new Date(element.availbilty.date + 'T' + element.availbilty.time),
          end: new Date(element.availbilty.date + 'T' + element.availbilty.endTime),
          title: isUser()
            ? getHtml(view)
            : intl.formatMessage({
                id: 'AVAILABLE'
              }),
          type: 'Interprator',
          availbilty: element.availbilty
        };
        tempEvents.push(tempobj);
      } else {
        tempEvents.push(element);
      }
    });
    setEvent(tempEvents);
  };

  const formik = useFormik({
    initialValues
  });

  const removeFilter = () => {
    setAdminType(0);
    setSelectedUser(null);
    formik.setValues({ ...formik.values, userType: '', user: '', interpratorId: '' });
    event.length = 0;
  };

  const changeUserSelect = () => {
    setAdminType(0);
    setSelectedUser(null);
    event.length = 0;
    // formik.setValues({ ...formik.values, user: '', interpratorId: '' });
  };

  const formats = {
    timeGutterFormat: 'HH:mm', // Format for the time column on the left side
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`, // Format for event time ranges
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`, // Format for time ranges in agenda view
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'MMM DD', culture)} - ${localizer.format(end, 'MMM DD', culture)}` // Format for the header of each day
  };

  return (
    <div>
      <div className="dashboard_legend">
        <div style={{ position: 'fixed', right: '0', top: '70px', zIndex: '99' }}>
          <div className="form-group row">
            {isAdmin() || isUser() ? (
              <button
                type="button"
                onClick={createappointment}
                style={{ marginRight: '15px', background: '#eb457b', color: '#fff' }}
                className="btn btn-light btn-elevate"
              >
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                <FormattedMessage id="DASHBOARD.ADD_APPOINTMENT" />
              </button>
            ) : null}

            {isAdmin() ? (
              <button
                type="button"
                onClick={createschedule}
                style={{ marginRight: '45px', background: '#687cfc', color: '#fff' }}
                className="btn btn-light btn-elevate"
              >
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                <FormattedMessage id="DASHBOARD.ADD_SCHEDULE" />
              </button>
            ) : null}
          </div>
        </div>
        <div className="mr-8">
          <span
            className="dashboard_legend_color mr-3"
            style={{ backgroundColor: 'lightgreen' }}
          ></span>
          <FormattedMessage id="DASHBOARD.ACCEPTED" />
        </div>

        <div className="mr-8">
          <span
            className="dashboard_legend_color mr-3"
            style={{ backgroundColor: '#c5dd0d' }}
          ></span>
          <FormattedMessage id="DASHBOARD.INTERPRATORPENDING" />
        </div>

        <div className="mr-8">
          <span className="dashboard_legend_color mr-3" style={{ backgroundColor: 'red' }}></span>
          <FormattedMessage id="DASHBOARD.ADMINPENDING" />
        </div>

        <div className="mr-8">
          <span
            className="dashboard_legend_color mr-3"
            style={{ backgroundColor: 'lightgray' }}
          ></span>
          <FormattedMessage id="DASHBOARD.DELETED" />
        </div>

        <div className="mr-8">
          <span
            className="dashboard_legend_color mr-3"
            style={{ backgroundColor: 'lightblue' }}
          ></span>
          <FormattedMessage id="DASHBOARD.INPERSON_AVAILABILITY" />
        </div>

        <div className="mr-8">
          <span
            className="dashboard_legend_color mr-3"
            style={{ backgroundColor: 'lightyellow' }}
          ></span>
          <FormattedMessage id="DASHBOARD.CALL_AVAILABILITY" />
        </div>
        <div className="mr-8">
          <span className="dashboard_legend_color mr-3" style={{ backgroundColor: '#eb457b' }}></span>
          <FormattedMessage id="DASHBOARD.CALL_CENTER_AVAILABILITY" />
        </div>
      </div>

      {!onSiteToggle ? AVVISOSOSPENSIONECALENDAR : null}
      <div className="row">
        {isAdmin() || isAdminVerified() ? (
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-flex' }}>
              {isAdmin() ? (
                <div style={{ display: 'inline-flex' }}>
                  <div className="form-group row">
                    <label className="ml-3 mr-4 col-form-label">
                      <FormattedMessage id="DASHBOARD.USER_TYPE" />
                    </label>
                    <div className="" style={{ width: 150 }}>
                      <div className="input-group input-group-lg input-group-solid">
                        <Select
                          className={`w-100 h-auto interpiter_menu`}
                          name="userType"
                          value={userTypes.filter((e) => e.value === formik.values.userType)}
                          onChange={(e) => {
                            formik.setFieldValue('userType', e.value);
                            changeUserSelect();
                          }}
                          isOptionDisabled={(option) => option.disabled}
                          options={userTypes}
                        />

                        {/* <select
                          className={`form-control form-control-solid h-auto interpiter_menu`}
                          name="userType"
                          onChangeCapture={changeUserSelect}
                          {...formik.getFieldProps('userType')}
                        >
                          <option value="" disabled>
                            {intl.formatMessage({
                              id: 'DASHBOARD_SELECT_USER_TYPE'
                            })}
                          </option>
                          <option key={1} value={1}>
                            {intl.formatMessage({
                              id: 'DASHBOARD.USER'
                            })}
                          </option>
                          <option key={2} value={2}>
                            {intl.formatMessage({
                              id: 'DASHBOARD.INTERPRATOR'
                            })}
                          </option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  {formik.values.userType === 1 ? (
                    <div className="form-group row ml-8">
                      <label className="ml-3 mr-4 col-form-label">
                        <FormattedMessage id="DASHBOARD.USER" />
                      </label>
                      <div className="" style={{ width: 300 }}>
                        <div className="input-group input-group-lg input-group-solid">
                          <Select
                            className={`w-100 h-auto interpiter_menu`}
                            name="user"
                            value={userOption.filter((e) => e.value === formik.values.user)}
                            onChange={(e) => {
                              formik.setFieldValue('user', e.value);
                              getAdminUserAppointmentById(e.value);
                            }}
                            options={userOption}
                            isSearchable
                          />

                          {/* <select
                            className={`form-control form-control-solid h-auto`}
                            name="user"
                            onChangeCapture={(e) => getAdminUserAppointmentById(e.target.value)}
                            {...formik.getFieldProps('user')}
                          >
                            <option value="" disabled>
                              {intl.formatMessage({
                                id: 'DASHBOARD_SELECT_USER'
                              })}
                            </option>
                            {users.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.firstName} {user.lastName}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {formik.values.userType === 2 ? (
                    <div className="form-group row ml-5">
                      <label className="ml-3 mr-4 col-form-label">
                        <FormattedMessage id="DASHBOARD.INTERPRATOR" />
                      </label>
                      <div className="" style={{ width: 300 }}>
                        <div className="input-group input-group-lg input-group-solid">
                          <Select
                            className={`w-100 h-auto interpiter_menu`}
                            name="interpratorId"
                            value={interpreterOption.filter(
                              (e) => e.value === formik.values.interpratorId
                            )}
                            onChange={(e) => {
                              formik.setFieldValue('interpratorId', e.value);
                              getAdminInterAppointmentById(e.value);
                            }}
                            options={interpreterOption}
                            isSearchable
                          />

                          {/* <select
                            className={`form-control form-control-solid h-auto`}
                            name="interpratorId"
                            onChangeCapture={(e) => getAdminInterAppointmentById(e.target.value)}
                            {...formik.getFieldProps('interpratorId')}
                          >
                            <option value="" disabled>
                              {intl.formatMessage({
                                id: 'DASHBOARD_SELECT_INTERPRATOR'
                              })}
                            </option>
                            {interprator.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.firstName} {user.lastName}
                                </option>
                              );
                            })}
                          </select>
                        */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formik.values.userType !== '' ? (
                    <span
                      onClick={removeFilter}
                      className="ml-8"
                      style={{ lineHeight: 3, cursor: 'pointer' }}
                    >
                      {' '}
                      <FormattedMessage id="DASHBOARD.REMOVE_FILTER" />{' '}
                    </span>
                  ) : null}
                </div>
              ) : null}

              {/* <div style={{ position: 'absolute', right: '15px' }}>
              <div className="form-group row">
                {isAdmin() || isUser() ? (
                  <button
                    type="button"
                    onClick={createappointment}
                    style={{ marginRight: '15px', background: '#4fa4fb', color: '#fff' }}
                    className="btn btn-light btn-elevate"
                  >
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                    <FormattedMessage id="DASHBOARD.ADD_APPOINTMENT" />
                  </button>
                ) : null}

                {isAdmin() || isInterpreter() ? (
                  <button
                    type="button"
                    onClick={createschedule}
                    style={{ marginRight: '15px', background: '#c9f7f5', color: '#000' }}
                    className="btn btn-light btn-elevate"
                  >
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')} />
                    <FormattedMessage id="DASHBOARD.ADD_SCHEDULE" />
                  </button>
                ) : null}
              </div>
            </div> */}
            </div>
            {(isInterpreter() && !acceptPopup && modalShow) ||
            (adminType === 2 && isAdmin() && modalShow && !acceptPopup) ||
            (isAdmin() && modalShow && !acceptPopup && externalschbtn) ? (
              <SchedulePopup
                modalShow={modalShow}
                scheduleobj={scheduleobj}
                onModelHide={() => onModelHide(currentView)}
              ></SchedulePopup>
            ) : null}
            {(isUser() && modalShow) ||
            (adminType === 1 && isAdmin() && modalShow) ||
            (isAdmin() && modalShow && externalaptbtn) ? (
              <ApointmentPopup
                modalShow={modalShow}
                appointmentModel={appointmentModel}
                onModelHide={() => onModelHide(currentView)}
              ></ApointmentPopup>
            ) : null}
            {(isInterpreter() && acceptPopup && modalShow) ||
            (adminType === 2 && isAdmin() && modalShow && acceptPopup) ? (
              <AcceptPopup
                modalShow={modalShow}
                appointmentModel={appointmentModel}
                onModelHide={() => onModelHide(currentView)}
              ></AcceptPopup>
            ) : null}

            {isInterpreter() && showAppInt ? (
              <AcceptPopup
                modalShow={showAppInt}
                appointmentModel={appModalInt}
                onModelHide={() => {
                  onModelHide(currentView);
                  setShowAppInt(false);
                  props.history.push('/Interpreter/calendar');
                }}
              ></AcceptPopup>
            ) : null}

            <Calendar
              selectable
              localizer={localizer}
              events={event}
              startAccessor="start"
              views={['week', 'day', 'month']}
              endAccessor="end"
              defaultView="week"
              formats={formats}
              style={
                isAdmin()
                  ? { height: '95vh', width: '100%' }
                  : { height: '95vh', width: '100%', marginTop: '50px' }
              }
              onSelectEvent={onEventSelect}
              onSelectSlot={(event) => handleSelect(event)}
              dayLayoutAlgorithm="no-overlap"
              messages={{
                next: intl.formatMessage({
                  id: 'NEXT'
                }),
                previous: intl.formatMessage({
                  id: 'PREVIOUS'
                }),
                today: intl.formatMessage({
                  id: 'TODAY'
                }),
                work_week: intl.formatMessage({ id: 'WORK_WEEK' }),
                day: intl.formatMessage({ id: 'DAY' }), // "Giorno"
                month: intl.formatMessage({ id: 'MONTH' }), // "Mese"
                week: intl.formatMessage({ id: 'WEEK' }) // "Settimana"
              }}
              onView={(view) => {
                setCurrentView(view);
                onModelHide(view);
              }}
              eventPropGetter={(event) => {
                //console.log('EVENTO', event);
                let newStyle = {
                  backgroundColor: 'lightblue',
                  color: 'black',
                  borderRadius: '0px',
                  border: 'none'
                };

                if (event.status === 2) {
                  newStyle.backgroundColor = '#c5dd0d';
                }

                if (event.status === 1) {
                  newStyle.backgroundColor = 'lightgreen';
                }

                if (event.status === 0) {
                  newStyle.backgroundColor = 'red';
                }

                if (event.availbilty !== undefined && event.availbilty.online) {
                  newStyle.backgroundColor = event.availbilty.isCallCenter
                    ? '#eb457b'
                    : 'lightyellow';
                }

                if (
                  event.appointmentobj !== undefined &&
                  event.appointmentobj.videoCode &&
                  event.status === 1
                ) {
                  newStyle.backgroundColor = 'navy';
                  newStyle.color = 'white';
                }

                if (event.appointmentobj !== undefined) {
                  if (event.appointmentobj.is_deleted) {
                    newStyle.backgroundColor = 'lightgray';
                  }
                }

                if (
                  (isInterpreter() && event.type === 'appointment') ||
                  (isAdmin() && event.type === 'appointment')
                ) {
                  if (event.appointmentobj.is_deleted) newStyle.backgroundColor = 'lightgray';
                }

                if (isUser()) {
                  if (event.type === 'Interprator' && currentView === 'month')
                    newStyle.backgroundColor = 'transparent';
                  //newStyle.width="35px"
                }

                return {
                  className: '',
                  style: newStyle
                };
              }}
              min={getFirstDayOfWeek()}
              max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)}
              // weekends={false}
              // hiddenDays={[6, 0]}
            />
          </div>
        ) : (
          <div className="confiormation-center">
            <span className="confiormation-text">
              {' '}
              <FormattedMessage id="DASHBOARD.ACCOUNT_NOT_ACTIVE" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(null, mapDispatchToEvents)(DashboardPage));
