import React, { useEffect, useState } from 'react';
import PositionedModal from '../../../_ens/layout/components/modal/modal';
import DeleteModal from '../../../_ens/layout/components/Confirmation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  deleteAppointment,
  getAdminSettings,
  MakeAppointment,
  modifyAppointment
} from '../../modules/Admin/AdminActions';
import PlacesAutocomplete from 'react-places-autocomplete';
import { isAdmin } from '../../servics/CommonFunctions';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch
} from '@material-ui/core';
import { connect } from 'react-redux';

import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { APILINK } from '../../../config/config';
import { bindActionCreators } from 'redux';
import * as common from '../../../redux/commonReducer';
import { userAvailableService } from '../../modules/UserSingleCall/Action';
import { getAllServiceTypes } from '../../services/generic.service';
import { isMap } from 'lodash';

const titleOptions = (await getAllServiceTypes()).data;

function ApointmentPopup(props) {
  const { intl } = props;
  const [onSiteToggle, setOnSiteToggle] = useState(true);
  const [geoRule, setGeoRule] = useState(false);
  const [noEnte, setNoEnte] = useState(false);
  const [youTubeLink, setYouTubeLink] = useState();
  const [youTubeLink2, setYouTubeLink2] = useState();
  const [noticeText, setNoticeText] = useState();
  const [onsiteAppointmentMaxDuration, setOnsiteAppointmentMaxDuration] = useState();

  const [modalShow, setmodalShow] = useState(() => false);
  const [address, setAddress] = useState('');
  const [initialValues] = useState(() => props.appointmentModel);
  const [interpratorList] = useState(props.appointmentModel.interpratorList);
  const [userList] = useState(props.appointmentModel.userList);
  const [affiliatedOption] = useState(props.appointmentModel.affiliatedOption);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [Addressvalid, setConfirmAddress] = useState(false);
  const [Termsvalid, setConfirmTerms] = useState(false);
  const [videoAppointment, setVideoAppointment] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [, setIsSelected] = useState(false);
  let history = useHistory();

  const [aptStatus, setAptStatus] = useState(
    intl.formatMessage({
      id: 'DASHBOARD.APPOINTMENT'
    })
  );

  const appointmentDateTime =
    props.appointmentModel.final_date && props.appointmentModel.final_end_time
      ? new Date(`${props.appointmentModel.final_date}T${props.appointmentModel.final_end_time}`)
      : null;

  const now = new Date();

  useEffect(() => {
    getAdminSettings().then((res) => {
      setOnsiteAppointmentMaxDuration(res.data.onsiteAppointmentMaxDuration);
      setOnSiteToggle(res.data.onsiteAppointmentStatus);
      setGeoRule(res.data.geoRule);
      setNoEnte(res.data.noEnte);
      setYouTubeLink(res.data.youTubeLink);
      setYouTubeLink2(res.data.youTubeLink2);
      setNoticeText(res.data.noticeText);
    });
  }, []);

  useEffect(() => {
    setmodalShow(props.modalShow);
    props.commonActions.openModal(true);
    if (!!props.appointmentModel.videoCode) {
      setVideoAppointment(true);
    }
    setAddress(props.appointmentModel.place ? props.appointmentModel.place : '');
    if (
      props.appointmentModel.appointmentStatuses &&
      props.appointmentModel.appointmentStatuses[0].status === 1
    ) {
      let string =
        intl.formatMessage({
          id: 'DASHBOARD.ACCEPTED'
        }) +
        ' (' +
        props.appointmentModel.appointmentStatuses[0].account.firstName +
        ' ' +
        props.appointmentModel.appointmentStatuses[0].account.lastName +
        ' - ' +
        props.appointmentModel.appointmentStatuses[0].account.phone +
        ')';
      setAptStatus(
        intl.formatMessage({
          id: 'DASHBOARD.APPOINTMENT'
        }) +
          ' | ' +
          string
      );
    }
    if (
      props.appointmentModel.appointmentStatuses &&
      props.appointmentModel.appointmentStatuses[0].status === 0
    ) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.ADMINPENDING'
      });
      setAptStatus(
        intl.formatMessage({
          id: 'DASHBOARD.APPOINTMENT'
        }) +
          ' | ' +
          string
      );
    }

    if (
      props.appointmentModel.appointmentStatuses &&
      props.appointmentModel.appointmentStatuses[0].status === 2
    ) {
      let string = intl.formatMessage({
        id: 'DASHBOARD.INTERPRATORPENDING'
      });
      setAptStatus(
        intl.formatMessage({
          id: 'DASHBOARD.APPOINTMENT'
        }) +
          ' | ' +
          string
      );
    }
    return () => {
      setmodalShow(false);
      // setIntialValues(scheduleCopy)
      formik.resetForm(() => {
        console.log('reset done');
      });
    };
  }, [props]);

  const AppointmentSchema = Yup.object().shape({
    date: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    startTime: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    endTime: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    description: Yup.string().when('title', {
      is: 'zoom_link',
      then: Yup.string()
        .required(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          })
        )
        .max(
          255,
          intl.formatMessage({
            id: 'MAXIMUM'
          }) +
            '500' +
            intl.formatMessage({
              id: 'SYMBOLS'
            })
        ),
      otherwise: Yup.string().max(
        255,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '500' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
    }),

    title: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        500,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '500' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    mode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    return '';
  };

  const onModelHide = () => {
    setmodalShow(false);
    props.onModelHide();
    props.commonActions.openModal(false);
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
  };

  const handleVideoAppointment = (event) => {
    const newValue = event.target.checked;
    setVideoAppointment(newValue);
  };

  const handleToggleAppointment = async (isVideo) => {
    setVideoAppointment(isVideo);
    if (!isVideo) {
      await validateTimeDuration(formik.values.startTime, formik.values.endTime);
    }
  };

  const fileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();

      if (!address && !geoRule && !videoAppointment) {
        confirmAddress(true);
        setSubmitting(false);
        return;
      }

      if (!props.appointmentModel.id && !isAdmin() && videoAppointment) {
        if (!values.acceptTerms) {
          confirmTerms(true);
          setSubmitting(false);
          return;
        }
      }

      if (selectedFile !== undefined) {
        formData.append('appointment_file', selectedFile);
      }

      if (!videoAppointment) {
        const exceedsLimit = await validateTimeDuration(values.startTime, values.endTime, false);
        if (exceedsLimit) {
          setSubmitting(false);
          return;
        }
      }

      setSubmitting(true);
      if (props.appointmentModel.id) {
        formData.append('description', values.description);
        formData.append('title', values.title);
        formData.append('id', values.id);
        formData.append('startTime', values.startTime);
        formData.append('endTime', values.endTime);
        formData.append('date', values.date);
        if (values.place && values.place !== '') {
          formData.append('place', values.place);
        } else if (videoAppointment) {
          formData.append('place', 'VIDEO');
        } else {
          formData.append('place', 'ENTE');
        }
        if (values.affiliatedstructureId && values.affiliatedstructureId !== '') {
          formData.append('affiliatedstructureId', values.affiliatedstructureId);
        } else {
          formData.append('affiliatedstructureId', 0);
        }
        if (isAdmin()) {
          // datatosend.interpreterId = values.selectedInterprator;

          formData.append('interpreterId', values.selectedInterprator);
        }

        modifyAppointment(formData)
          .then(() => {
            props.onModelHide();
          })
          .catch((error) => {
            console.log("Errore durante la creazione dell'appuntamento:", error);
            if (error.response && error.response.data && error.response.data.message) {
              props.commonActions.showSnackBar(true, error.response.data.message, 'error');
            } else {
              props.commonActions.showSnackBar(
                true,
                "Si è verificato un errore durante la creazione dell'appuntamento.",
                'error'
              );
            }
            props.onModelHide();
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        values.userId = parseInt(values.userId);
        values.place = address;

        if (values.mode === 'inPerson' && values.place === '') {
          //show errr
        }
        // let datatosend = {
        //   description: values.description,
        //   title: values.title,
        //   id: values.id,
        //   startTime: values.startTime,
        //   endTime: values.endTime,
        //   date: values.date,
        //   place: values.place,
        //   userId: values.userId,
        //   mode: values.mode,
        //   interpreterId: values.selectedInterprator
        // };
        formData.append('description', values.description);
        formData.append('title', values.title);
        formData.append('id', values.id);
        formData.append('startTime', values.startTime);
        formData.append('endTime', values.endTime);
        formData.append('date', values.date);
        if (values.place && values.place !== '') {
          formData.append('place', values.place);
        } else if (videoAppointment) {
          formData.append('place', 'VIDEO');
        } else {
          formData.append('place', 'ENTE');
        }
        formData.append('mode', values.mode);
        formData.append('interpreterId', values.selectedInterprator);
        if (values.affiliatedstructureId && values.affiliatedstructureId !== '') {
          formData.append('affiliatedstructureId', values.affiliatedstructureId);
        } else {
          formData.append('affiliatedstructureId', 0);
        }
        formData.append('userId', values.userId);

        MakeAppointment(formData, videoAppointment)
          .then(() => {
            props.onModelHide();
          })
          .catch((error) => {
            console.log("Errore durante la creazione dell'appuntamento:", error);
            if (error.response && error.response.data && error.response.data.message) {
              props.commonActions.showSnackBar(true, error.response.data.message, 'error');
            } else {
              props.commonActions.showSnackBar(
                true,
                "Si è verificato un errore durante la creazione dell'appuntamento.",
                'error'
              );
            }
            props.onModelHide();
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  });

  const deleteAvailbility = () => {
    deleteAppointment(props.appointmentModel.id)
      .then(() => {
        onModelHide();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          props.commonActions.showSnackBar(true, error.response.data.message, 'error');
        } else {
          props.commonActions.showSnackBar(
            true,
            "Si è verificato un errore durante l'eliminazione dell'appuntamento.",
            'error'
          );
        }
        onModelHide();
      });
  };

  const setConfirm = () => {
    setConfirmDelete(true);
  };

  const confirmAddress = (value) => {
    setConfirmAddress(value);
  };

  const confirmTerms = (value) => {
    setConfirmTerms(value);
  };

  const downlodaFile = async (appointment_file) => {
    fetch(APILINK + appointment_file, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${props.auth.authToken}`
      }
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = appointment_file.replace('/appointment/', '');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const onCallUser = (videoCode) => {
    userAvailableService({ videoCode })
      .then(() => {
        history.push('/singlecall/', videoCode);
      })
      .catch((error) => {
        console.log('ERRORE VIDEO CALL', error.response);
        alert(
          "Si è verificato un errore durante l'avvio della videochiamata. Si prega di riprovare."
        );
      });
  };
  const validateTimeDuration = async (startTime, endTime, changeEndTime = true) => {
    if (!isAdmin()) {
      const { exceed, maxEndTime, maxDurationFormatted } = calculateTimeDuration(
        startTime,
        endTime
      );

      if (exceed) {
        if (changeEndTime) {
          await formik.setFieldValue('endTime', maxEndTime);
        }

        // Mostra il messaggio di errore con un piccolo ritardo per evitare problemi di sincronizzazione
        setTimeout(() => {
          props.commonActions.showSnackBar(
            true,
            intl.formatMessage(
              { id: 'DASHBOARD_DURATION_EXCEEDED' },
              { maxDuration: maxDurationFormatted }
            ),
            'error',
            3000
          );
        }, 100);
      }

      return exceed;
    } else return false;
  };
  const calculateTimeDuration = (startTime, endTime) => {
    const startMoment = moment(startTime, 'HH:mm');
    const endMoment = moment(endTime, 'HH:mm');
    const duration = moment.duration(endMoment.diff(startMoment));
    const maxDurationMinutes = moment.duration(onsiteAppointmentMaxDuration).asMinutes();

    const exceed = duration.asMinutes() > maxDurationMinutes;
    const maxEndTime = startMoment.add(maxDurationMinutes, 'minutes').format('HH:mm');

    // Formatta la durata massima in un formato leggibile
    const maxDurationFormatted = formatDuration(maxDurationMinutes);

    return { exceed, maxEndTime, maxDurationFormatted };
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours} or${hours > 1 ? 'e' : 'a'}` : ''}${
      mins > 0 ? ` e ${mins} minut${mins > 1 ? 'i' : 'o'}` : ''
    }`.trim();
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={Addressvalid}
      >
        <DialogTitle id="confirmation-dialog-title">
          {intl.formatMessage({
            id: 'ALERT'
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {intl.formatMessage({
              id: 'APPOINTMENT.ADDRESS_REQUIRED'
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={() => {
              confirmAddress(false);
            }}
            className="btn btn-elevate"
          >
            {intl.formatMessage({
              id: 'OK'
            })}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={Termsvalid}
      >
        <DialogTitle id="confirmation-dialog-title">
          {intl.formatMessage({
            id: 'ALERT'
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {intl.formatMessage({
              id: 'APPOINTMENT.TERMS_REQUIRED'
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={() => {
              confirmTerms(false);
            }}
            className="btn btn-elevate"
          >
            {intl.formatMessage({
              id: 'OK'
            })}
          </button>
        </DialogActions>
      </Dialog>

      <DeleteModal
        open={confirmDelete}
        title={intl.formatMessage({
          id: 'CONFIRMATION'
        })}
        msg={intl.formatMessage({
          id: 'APPOINTMENT_DELETE'
        })}
        handleCancel={() => setConfirmDelete(false)}
        cacelColor="btn btn-light btn-elevate"
        cancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        handleOk={deleteAvailbility}
        okColor="btn btn-delete btn-elevate"
        okText={intl.formatMessage({
          id: 'DELETE'
        })}
      ></DeleteModal>

      <PositionedModal
        center={true}
        isDeleted={props.appointmentModel.is_deleted}
        show={modalShow}
        title={aptStatus}
        onHide={onModelHide}
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        {...(appointmentDateTime === null ||
        (appointmentDateTime && appointmentDateTime > now) ||
        isAdmin()
          ? {
              deletebtn: setConfirm,
              deletetext: props.appointmentModel.id
                ? intl.formatMessage({
                    id: 'DELETE'
                  })
                : '',
              onSubmit: formik.handleSubmit,
              isSubmitting: formik.isSubmitting,
              updateText: intl.formatMessage({
                id: 'SAVE'
              })
            }
          : {})}
        deleteClass="btn btn-delete btn-elevate"
      >
        {onSiteToggle ? (
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="form-group row">
              {/* First Name */}
              {!props.appointmentModel.id && (
                <div className="col-md-12">
                  <div>
                    <label className="col-form-label">
                      <FormattedMessage id="DASHBOARD.ONLINEAPPT" />
                    </label>
                  </div>
                  <div className="display-none">
                    <Switch
                      checked={videoAppointment}
                      onChange={handleVideoAppointment}
                      name="videoAppointment"
                    />
                  </div>
                  <div className="btn-group mt-3" role="group" aria-label="Appointment Type">
                    <button
                      type="button"
                      className={`btn btn-lg ${
                        !videoAppointment ? 'btn-primary' : 'btn-secondary'
                      }`}
                      onClick={() => handleToggleAppointment(false)}
                    >
                      In Presenza
                    </button>
                    <button
                      type="button"
                      className={`btn btn-lg ${videoAppointment ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => handleToggleAppointment(true)}
                    >
                      A Distanza
                    </button>
                  </div>
                </div>
              )}

              <div className="col-lg-6">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.DATE" />
                </label>
                <input
                  readOnly={props.appointmentModel.id && !isAdmin()}
                  placeholder="Date"
                  type="date"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'date'
                  )}`}
                  name="date"
                  {...formik.getFieldProps('date')}
                />
                {formik.touched.date && formik.errors.date ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.date}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-lg-3">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.START_TIME" />
                </label>
                <input
                  placeholder="Start Time"
                  type="time"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'startTime'
                  )}`}
                  name="startTime"
                  readOnly={props.appointmentModel.id && !isAdmin()}
                  {...formik.getFieldProps('startTime')}
                />
                {formik.touched.startTime && formik.errors.startTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.startTime}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-lg-3">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.END_TIME" />
                </label>
                <input
                  placeholder="End Time"
                  type="time"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'endTime'
                  )}`}
                  name="endTime"
                  readOnly={props.appointmentModel.id && !isAdmin()}
                  {...formik.getFieldProps('endTime')}
                  onBlur={async (e) => {
                    if (!videoAppointment)
                      await validateTimeDuration(formik.values.startTime, e.target.value);
                  }}
                />
                {formik.touched.endTime && formik.errors.endTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endTime}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.TITLE" />
                </label>
                {/* <input
                placeholder={intl.formatMessage({
                  id: 'DASHBOARD.TITLE'
                })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'title'
                )}`}
                name="title"
                {...formik.getFieldProps('title')}
              /> */}
                {/* change in title */}
                <Select
                  options={titleOptions}
                  name="title"
                  value={titleOptions.filter((data) => data.value === formik.values.title)}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'title'
                  )}`}
                  onChange={(e) => formik.setFieldValue(`title`, e.value)}
                />

                {formik.touched.title && formik.errors.title ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                ) : null}
              </div>
            </div>
            {!videoAppointment ? (
              <>
                {!geoRule ? (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label className="col-form-label">
                        <FormattedMessage id="DASHBOARD.ADDRESS" />
                      </label>
                      <PlacesAutocomplete
                        searchOptions={{ types: ['address'], language: 'it' }}
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: intl.formatMessage({
                                  id: 'DASHBOARD.SEARCH_PLACES'
                                }),
                                name: 'address',
                                className: 'form-control form-control-solid h-auto py-5 px-6'
                              })}
                              readOnly={props.appointmentModel.id && !isAdmin()}
                            />

                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                let className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                className = className + ' autocomplete-custom';
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                ) : (
                  <div className={'form-group row ' + (noEnte ? 'display-none' : '')}>
                    <div className="col-lg-12">
                      <label className="col-form-label">
                        <FormattedMessage id="DASHBOARD.AFFILIATED" />
                      </label>

                      <Select
                        options={affiliatedOption}
                        name="affiliatedstructureId"
                        value={affiliatedOption.filter(
                          (data) => data.value === formik.values.affiliatedstructureId
                        )}
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          'affiliatedstructureId'
                        )}`}
                        onChange={(e) => formik.setFieldValue(`affiliatedstructureId`, e.value)}
                        isSearchable
                      />
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <div className="form-group row">
              <div className="col-lg-12">
                {formik.values.title === 'zoom_link' ? (
                  <label className="col-form-label">
                    <FormattedMessage id="DASHBOARD.DESCRIPTION.ZOOM_LINK" />
                  </label>
                ) : (
                  <label className="col-form-label">
                    <FormattedMessage id="DASHBOARD.DESCRIPTION" />
                  </label>
                )}
                <textarea
                  placeholder={
                    formik.values.title === 'zoom_link'
                      ? intl.formatMessage({
                          id: 'DASHBOARD.DESCRIPTION.ZOOM_LINK'
                        })
                      : intl.formatMessage({
                          id: 'DASHBOARD.DESCRIPTION'
                        })
                  }
                  rows="4"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'description'
                  )}`}
                  name="description"
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.description}</div>
                  </div>
                ) : null}
                {formik.touched.description && formik.errors.descriptionEmpty ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.descriptionEmpty}</div>
                  </div>
                ) : null}
              </div>
            </div>
            {!isAdmin() &&
            props.appointmentModel.id &&
            props.appointmentModel.videoCode !== '' &&
            props.appointmentModel.videoCode !== null &&
            props.appointmentModel.appointmentStatuses[0].status === 1 &&
            moment(props.appointmentModel.date).isSame(moment(), 'day') ? (
              <div className="form-group row">
                <div className="col-lg-12 text-center">
                  <Button
                    variant="danger"
                    className="fnt-videocall"
                    onClick={() => onCallUser(props.appointmentModel.videoCode)}
                  >
                    AVVIA VIDEOCHIAMATA
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
            {isAdmin() ? (
              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="col-form-label">
                    <FormattedMessage id="DASHBOARD.INTERPRATOR" />
                  </label>

                  <select
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      'selectedInterprator'
                    )}`}
                    name="selectedInterprator"
                    {...formik.getFieldProps('selectedInterprator')}
                  >
                    <option key="" disabled value="">
                      {intl.formatMessage({
                        id: 'DASHBOARD_SELECT_INTERPRATOR'
                      })}
                    </option>
                    {interpratorList.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {isAdmin() && !props.appointmentModel.id && props.appointmentModel.userId === '' ? (
                  <div className="col-lg-6">
                    <label className="col-form-label">
                      <FormattedMessage id="DASHBOARD.USER" />
                    </label>

                    <select
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        'userId'
                      )}`}
                      name="userId"
                      {...formik.getFieldProps('userId')}
                    >
                      <option key="" disabled value="">
                        {intl.formatMessage({
                          id: 'DASHBOARD_SELECT_USER'
                        })}
                      </option>
                      {userList.map((user) => {
                        return (
                          <option key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}
              </div>
            ) : null}
            {isAdmin() || (!isAdmin() && !props.appointmentModel.id) ? (
              <div className="form-group fv-plugins-icon-container">
                <label
                  className="btn btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  style={{ width: '100%', textAlign: 'left' }}
                  title=""
                  data-original-title="Id Proof"
                >
                  <span>
                    {' '}
                    <FormattedMessage id="APPOINTMENT_FILE_UPLOAD" />
                  </span>{' '}
                  <br />
                  <input
                    type="file"
                    className="mt-5"
                    style={{ width: '200px' }}
                    name="appointment_file"
                    onChange={(e) => {
                      fileSelect(e);
                    }}
                    accept=".png, .jpg, .jpeg, .mp4, .pdf, .docx"
                  />
                </label>
              </div>
            ) : (
              ''
            )}
            {props?.appointmentModel?.id && props.appointmentModel.appointment_file !== null ? (
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="col-form-label mr-5">
                    <FormattedMessage id="APPOINTMENT_FILE" />
                  </label>
                  <span>
                    <a
                      onClick={() =>
                        downlodaFile(
                          props.appointmentModel.appointment_file,
                          props.appointmentModel
                        )
                      }
                    >
                      <FormattedMessage id="PROFILE.VIE_PROOF" />{' '}
                    </a>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            {(isAdmin() && props.appointmentModel.userId === '') ||
            props.appointmentModel.id ||
            !videoAppointment ? (
              ''
            ) : (
              <>
                <div className="form-group">
                  <label className="checkbox tick_in_front">
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      className="m-1"
                      {...formik.getFieldProps('acceptTerms')}
                    />
                    {/* <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
                    {' '}
                    <FormattedMessage id="AUTH.REGISTER.ACCEPT_TERMS" />
                  </Link> */}
                    <div className="mr-2">
                      <FormattedMessage id="BOOKAPPOINTMENTTERMSANDCONDITION" />
                    </div>
                    <span />
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </form>
        ) : (
          <div className="appuntamento_video_wrapper">
            <p>{noticeText}</p>
            {youTubeLink && youTubeLink !== '' && (
              <iframe
                src={`${youTubeLink}?autoplay=0`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {youTubeLink2 && youTubeLink2 !== '' && (
              <iframe
                src={`${youTubeLink2}?autoplay=0`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        )}
      </PositionedModal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(ApointmentPopup));
