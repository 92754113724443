import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import UpdateTimeAppointmentForm from './form';

import {
  getAppointmentUpdateData,
  updateAppointmentUpdateData
} from './updateTimeAppointmentAction';

function UpdateTimeAppointment(props) {
  const { intl } = props;
  const [, setLoading] = useState(false);
  // const [initialValues, setInitialValues] = useState(initialStateValue);
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const [appointmentData, setAppointmentData] = useState();

  let history = useHistory();

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.token) setToken(props.match.params.token);
      if (props.match.params.email) setEmail(props.match.params.email);
      if (props.match.params.token && props.match.params.email)
        getAppointmentData(props.match.params.token, props.match.params.email);
    }
  }, [props]);

  const getAppointmentData = async (token, email) => {
    const res = await getAppointmentUpdateData({ token, email });

    setAppointmentData({ ...res.data });
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const submitFunction = async (value) => {
    enableLoading();
    const data = {
      ...value
    };

    const res = await updateAppointmentUpdateData({ token: token, email: email }, data);

    disableLoading();

    if (res.data === true) {
      const alertMsg = intl.formatMessage({
        id: 'CHANGE_APPOINTMENT_TIME_ALERT'
      });
      alert(alertMsg);
      history.push('/auth/login');
    }
  };

  return (
    <div className="login-form login-signin update_appointment" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5">
        <h3 className="font-size-h1">
          <FormattedMessage id="UPDATE.TIME.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="UPDATE.TIME.SUBTITLE" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      {/* {console.log(appointmentData)} */}
      {appointmentData && (
        <div className="view_appointment_data">
          <div className="row">
            <div className="col-6 view_appointment_data_item title">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.TITLE" />
            </div>
            <div className="col-6 view_appointment_data_item title">{appointmentData.title}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.DESCRIPTION" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.description}</div>
          </div>
          {/* <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.PLACE" />
            </div>
            <div className="col-6 view_appointment_data_item">
              {appointmentData.affiliatedstructure.name}
            </div>
          </div> */}
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.PLACE" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.place}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.DATE" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.date}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.START.TIME" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.startTime}</div>
          </div>
          <div className="row">
            <div className="col-6 view_appointment_data_item">
              <FormattedMessage id="UPDATE.TIME.APPOINTMENT.END.TIME" />
            </div>
            <div className="col-6 view_appointment_data_item">{appointmentData.endTime}</div>
          </div>
          <div className="user-info">
            <div className="row">
              <div className="col-6 view_appointment_data_item">
                <FormattedMessage id="UPDATE.TIME.USER.NAME" />
              </div>
              <div className="col-6 view_appointment_data_item">
                {appointmentData.account.firstName + ' ' + appointmentData.account.lastName}
              </div>
            </div>
            <div className="row">
              <div className="col-6 view_appointment_data_item">
                <FormattedMessage id="UPDATE.TIME.USER.EMAIL" />
              </div>
              <div className="col-6 view_appointment_data_item">
                {appointmentData.account.email}
              </div>
            </div>
            <div className="row">
              <div className="col-6 view_appointment_data_item">
                <FormattedMessage id="UPDATE.TIME.USER.PHONE" />
              </div>
              <div className="col-6 view_appointment_data_item">
                {appointmentData.account.phone}
              </div>
            </div>
            <div className="row">
              <div className="col-6 view_appointment_data_item">
                <FormattedMessage id="UPDATE.TIME.USER.ADDRESS" />
              </div>
              <div className="col-6 view_appointment_data_item">
                {appointmentData.account.address}
              </div>
            </div>
          </div>
        </div>
      )}
      <UpdateTimeAppointmentForm submitFunction={submitFunction} />
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(UpdateTimeAppointment);
