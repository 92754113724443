import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import PositionedModal from '../../../../_ens/layout/components/modal/modal';
import { assignAppointment, allInterpreterMailForAppointment } from '../AdminActions';
import { connect } from 'react-redux';
import * as common from '../../../../redux/commonReducer';
import { bindActionCreators } from 'redux';
import { APILINK } from '../../../../config/config';

function UserPopup(props) {
  const { intl } = props;
  const initialValues = props.appointmentModel;
  useEffect(() => {}, [props.appointmentModel]);

  const AppointmentSchema = Yup.object().shape({
    interpreterId: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    return '';
  };

  const downlodaFile = async (appointment_file) => {
    fetch(APILINK + appointment_file, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${props.auth.authToken}`
      }
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = appointment_file.replace('/appointment/', '');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let datatoshare = {
        appointmentId: props.appointmentModel.id,
        interpreterId: values.interpreterId
      };

      assignAppointment(datatoshare)
        .then(() => {
          props.commonActions.showSnackBar(
            true,
            intl.formatMessage({
              id: 'ADMIN_APPOINTMENT_ASSIGNED_SUCCESS'
            })
          );
          props.updateCurrentUser();
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const mailAllInterpreter = () => {
    const appointmentId = props.appointmentModel.id;

    allInterpreterMailForAppointment(appointmentId).then((res) => {
      props.commonActions.showSnackBar(
        true,
        intl.formatMessage({
          id: 'ADMIN_APPOINTMENT_ASSIGNED_SUCCESS_ALL_INTERPRETER'
        })
      );
      props.onModelHide();
    });
  };

  return (
    <div>
      <PositionedModal
        center={true}
        show={props.modalShow}
        title={
          initialValues.id
            ? intl.formatMessage({
                id: 'ASSIGNAPPOINTMENT'
              })
            : intl.formatMessage({
                id: 'CREATEAPPOINTMENT'
              })
        }
        onHide={props.onModelHide}
        onSubmit={formik.handleSubmit}
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        updateText={
          initialValues.id
            ? intl.formatMessage({
                id: 'ASSIGN'
              })
            : intl.formatMessage({
                id: 'SAVE'
              })
        }
        isSubmitting={formik.isSubmitting}
        interpreterMail={true}
        onInterpreterMail={() => mailAllInterpreter()}
      >
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group row">
            {/* First Name */}
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DATE" />
              </label>
              <input
                readOnly={props.appointmentModel.id}
                placeholder="Date"
                type="date"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'date'
                )}`}
                name="date"
                {...formik.getFieldProps('date')}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_TIME" />
              </label>
              <input
                placeholder="Start Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'startTime'
                )}`}
                name="startTime"
                readOnly={props.appointmentModel.id}
                {...formik.getFieldProps('startTime')}
              />
              {formik.touched.startTime && formik.errors.startTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startTime}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-3">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.END_TIME" />
              </label>
              <input
                placeholder="End Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'endTime'
                )}`}
                name="endTime"
                readOnly={props.appointmentModel.id}
                {...formik.getFieldProps('endTime')}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.TITLE" />
              </label>
              <input
                placeholder="Title"
                type="text"
                readOnly={props.appointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'title'
                )}`}
                name="title"
                {...formik.getFieldProps('title')}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.ADDRESS" />
              </label>

              <input
                placeholder="Address"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'place'
                )}`}
                readOnly={props.appointmentModel.id}
                name="place"
                {...formik.getFieldProps('place')}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.DESCRIPTION" />
              </label>
              <textarea
                placeholder="description"
                rows="4"
                readOnly={props.appointmentModel.id}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'description'
                )}`}
                name="description"
                {...formik.getFieldProps('description')}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.description}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.INTERPRATOR" />
              </label>

              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'interpreterId'
                )}`}
                value=""
                name="interpreterId"
                {...formik.getFieldProps('interpreterId')}
              >
                <option disabled key="" value="">
                  {intl.formatMessage({
                    id: 'DASHBOARD_SELECT_INTERPRATOR'
                  })}
                </option>
                {props.interpreterList.map((res) => {
                  return (
                    <option key={res.id} value={res.id}>
                      {res.firstName} {res.lastName}
                    </option>
                  );
                })}
              </select>
              {formik.touched.interpreterId && formik.errors.interpreterId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.interpreterId}</div>
                </div>
              ) : null}
            </div>

            {props?.appointmentModel?.id && props.appointmentModel.appointment_file !== null ? (
              <div className="col-lg-6">
                <label className="col-form-label mr-5">
                  <FormattedMessage id="APPOINTMENT_FILE" />
                </label>
                <span className="d-block">
                  <button
                    type="button"
                    onClick={() =>
                      downlodaFile(props.appointmentModel.appointment_file, props.appointmentModel)
                    }
                  >
                    <FormattedMessage id="PROFILE.VIE_PROOF" />{' '}
                  </button>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </form>
      </PositionedModal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(UserPopup));
