import { Repository } from '../../servics/httpsServics';

const appointments = 'appointments';

export function getAppointmentToReject(data) {
  return Repository.get(appointments + '/pending/appt/', {
    params: data
  });
}

export function updateRejectedAppointment(paramsData, bodyData) {
  return Repository.post(appointments + '/update-rejected-appointment', bodyData, {
    params: paramsData
  });
}
