import React, { useEffect, useState } from 'react';
import PositionedModal from '../../../_ens/layout/components/modal/modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isAdmin, isInterpreter, weekDays } from '../../servics/CommonFunctions';
import DeleteModal from '../../../_ens/layout/components/Confirmation';
import {
  Availbility,
  CallAvailbility,
  deleteAvailbilities,
  deleteCallAvailbilities,
  modifyavailbility,
  modifyCallAvailbility
} from '../../modules/Admin/AdminActions';
import { bindActionCreators } from 'redux';
import * as common from '../../../redux/commonReducer';
import { Switch } from '@material-ui/core';
import { connect } from 'react-redux';

function SchedulePopup(props) {
  const { intl } = props;

  const [modalShow, setModalShow] = useState(() => false);
  const [initialValues, setIntialValues] = useState(() => props.scheduleobj);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [radiobtn, setradiobtn] = useState(0);
  const [interpratorList] = useState(props.scheduleobj.interpratorList);
  const [online, setOnline] = useState(!!props.scheduleobj.online);
  const [callCenter, setCallCenter] = useState(false);

  useEffect(() => {
    props.commonActions.openModal(true);
    setModalShow(props.modalShow);
    if (props.scheduleobj.id) {
      setIntialValues({ ...initialValues, reoccurring: 0 });
    }

    return () => {
      setModalShow(false);
      formik.resetForm(() => {
        console.log('reset done');
      });
    };
  }, [props]);

  const setRadiobtn = (val) => {
    setradiobtn(val);
  };

  const AppointmentSchema = Yup.object().shape({
    date: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    time: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    endTime: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .test('is-greater', 'Ora Fine deve essere maggiore di Ora inizio', function(value) {
        const { time } = this.parent; // Ottieni il valore di time
        if (!time || !value) return true;
        const timeObj = new Date(`01/01/2000 ${time}`);
        const endTimeObj = new Date(`01/01/2000 ${value}`);
        return endTimeObj > timeObj;
      })
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    return '';
  };

  const onModelHide = () => {
    setModalShow(false);
    props.onModelHide();
    props.commonActions.openModal(false);
  };

  const handleCheckbox = (e) => {
    let value = { ...initialValues };
    value[e.target.name] = e.target.checked;
    setIntialValues(value);
  };

  const handleAllCheckbox = (e) => {
    let currentValues = { ...initialValues };
    currentValues[e.target.name] = e.target.checked;
    for (let i = 0; i < 7; i++) {
      currentValues['day' + i] = e.target.checked;
    }
    setIntialValues(currentValues);
    //formik.setValues({...formik.values,currentValues})
  };

  const deleteAvailbility = () => {
    let datatosend = {
      id: props.scheduleobj.id,
      reoccurring: radiobtn
    };

    if (online) {
      deleteCallAvailbilities(datatosend).then((res) => {
        onModelHide();
      });
    } else {
      deleteAvailbilities(datatosend).then((res) => {
        onModelHide();
      });
    }
  };

  function compareTime(time1, time2) {
    const [hour1, minute1] = time1.split(':').map(Number);
    const [hour2, minute2] = time2.split(':').map(Number);

    if (hour1 === hour2) {
      return minute1 - minute2;
    } else {
      return hour1 - hour2;
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      if (props.scheduleobj.id) {
        // change by developer
        let datatosend = {
          id: values.id,
          time: values.time,
          endTime: values.endTime,
          date: values.date,
          reoccurring: radiobtn,
          isCallCenter: callCenter
        };
        if (online) {
          if (isAdmin()) {
            modifyCallAvailbility(datatosend)
              .then((res) => {
                props.onModelHide();
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            if (isInterpreter()) {
              if (
                compareTime(values.time, '08:30') >= 0 &&
                compareTime(values.endTime, '19:30') <= 0
              ) {
                modifyCallAvailbility(datatosend)
                  .then((res) => {
                    props.onModelHide();
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              } else {
                // Show an error message indicating the time is not within the allowed range
                props.commonActions.showSnackBar(
                  true,
                  'Il monitoraggio delle disponibilità online al di fuori degli orari diurni è limitato esclusivamente agli amministratori.',
                  'error'
                );
                setSubmitting(false);
              }
            }
          }
        } else {
          modifyavailbility(datatosend)
            .then((res) => {
              props.onModelHide();
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.message) {
                props.commonActions.showSnackBar(true, error.response.data.message, 'error');
              } else {
                props.commonActions.showSnackBar(
                  true,
                  'Si è verificato un errore durante il salvataggio della disponibilità.',
                  'error'
                );
              }
              props.onModelHide();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      } else {
        let tempArray = [];
        for (let i = 0; i < 7; i++) {
          if (initialValues['day' + i]) {
            tempArray.push(i);
          }
        }

        let dataToSend = {
          interpreterID: values.interpreterID,
          time: values.time,
          endTime: values.endTime,
          date: values.date,
          isCallCenter: callCenter
        };

        if (values.dateUntil !== '') dataToSend.dateUntil = values.dateUntil;
        if (tempArray.toString() !== '') dataToSend.repeatDays = tempArray.toString();
        if (online) {
          if (isAdmin()) {
            CallAvailbility(dataToSend)
              .then((val) => {
                props.onModelHide();
              })
              .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                  props.commonActions.showSnackBar(true, error.response.data.message, 'error');
                } else {
                  props.commonActions.showSnackBar(
                    true,
                    'Si è verificato un errore durante la il salvataggio della disponibilità.',
                    'error'
                  );
                }
                props.onModelHide();
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            if (isInterpreter()) {
              if (
                compareTime(values.time, '08:30') >= 0 &&
                compareTime(values.endTime, '19:30') <= 0
              ) {
                CallAvailbility(dataToSend)
                  .then((val) => {
                    props.onModelHide();
                  })
                  .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                      props.commonActions.showSnackBar(true, error.response.data.message, 'error');
                    } else {
                      props.commonActions.showSnackBar(
                        true,
                        'Si è verificato un errore durante la il salvataggio della disponibilità.',
                        'error'
                      );
                    }
                    props.onModelHide();
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              } else {
                // Show an error message indicating the time is not within the allowed range
                props.commonActions.showSnackBar(
                  true,
                  'Il monitoraggio delle disponibilità online al di fuori degli orari diurni è limitato esclusivamente agli amministratori.',
                  'error'
                );
                setSubmitting(false);
              }
            }
          }
        } else {
          Availbility(dataToSend)
            .then((val) => {
              props.onModelHide();
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.message) {
                props.commonActions.showSnackBar(true, error.response.data.message, 'error');
              } else {
                props.commonActions.showSnackBar(
                  true,
                  'Si è verificato un errore durante il salvataggio della disponibilità.',
                  'error'
                );
              }
              props.onModelHide();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }
    }
  });

  const handleOnline = (event) => {
    const newValue = event.target.checked;
    setOnline(newValue);
  };

  const handleToggleOnline = (isOnline) => {
    setOnline(isOnline);
  };

  return (
    <div>
      <DeleteModal
        open={confirmDelete}
        title={intl.formatMessage({
          id: 'CONFIRMATION'
        })}
        msg={intl.formatMessage({
          id: 'SCHEDULE_DELETE'
        })}
        handleCancel={() => setConfirmDelete(false)}
        cacelColor="btn btn-light btn-elevate"
        cancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        handleOk={deleteAvailbility}
        okColor="btn btn-delete btn-elevate"
        okText={intl.formatMessage({
          id: 'DELETE'
        })}
      ></DeleteModal>

      <PositionedModal
        center={true}
        show={modalShow}
        title={intl.formatMessage({
          id: 'DASHBOARD.SCHEDULR'
        })}
        onHide={onModelHide}
        onSubmit={formik.handleSubmit}
        updateText={intl.formatMessage({
          id: 'SAVE'
        })}
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        deletebtn={() => setConfirmDelete(true)}
        deletetext={
          props.scheduleobj.id
            ? intl.formatMessage({
                id: 'DELETE'
              })
            : ''
        }
        deleteClass="btn btn-delete btn-elevate"
        isSubmitting={formik.isSubmitting}
      >
        {(isAdmin() || isInterpreter()) && (
          <div className="form-group row">
            <div className="col-lg-12">
              <div>
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.SCHEDULECALL" />
                </label>
              </div>
              <div className="display-none">
                <Switch
                  readOnly={!!props.scheduleobj.id}
                  disabled={!!props.scheduleobj.id}
                  checked={online}
                  onChange={handleOnline}
                  name="videoAppointment"
                />
              </div>
              <div className="btn-group mt-3" role="group" aria-label="Appointment Type">
                <button
                  type="button"
                  className={`btn btn-lg ${
                    !online && !callCenter ? 'btn-primary' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    handleToggleOnline(false);
                    setCallCenter(false);
                  }}
                >
                  In Presenza
                </button>
                <button
                  type="button"
                  className={`btn btn-lg ${
                    online && !callCenter ? 'btn-primary' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    handleToggleOnline(true);
                    setCallCenter(false);
                  }}
                >
                  A Distanza
                </button>
                {isAdmin() && (
                  <button
                    type="button"
                    className={`btn btn-lg ${callCenter ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => {
                      handleToggleOnline(true);
                      setCallCenter(true);
                    }}
                  >
                    Call Center
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group row">
            {/* First Name */}
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_DATE" />
              </label>
              <input
                placeholder="Start Date"
                type="date"
                readOnly={props.scheduleobj.id && !isAdmin()}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'date'
                )}`}
                name="date"
                {...formik.getFieldProps('date')}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-6">
              {!props.scheduleobj.id ? (
                <div>
                  <label className="col-form-label">
                    <FormattedMessage id="DASHBOARD.END_DATE" />
                  </label>
                  <input
                    placeholder="End Date"
                    type="date"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      'dateUntil'
                    )}`}
                    name="dateUntil"
                    {...formik.getFieldProps('dateUntil')}
                  />
                  {formik.touched.dateUntil && formik.errors.dateUntil ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.dateUntil}</div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_TIME" />
              </label>
              <input
                placeholder="Start Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'time'
                )}`}
                name="time"
                {...formik.getFieldProps('time')}
              />
              {formik.touched.time && formik.errors.time ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.time}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.END_TIME" />
              </label>
              <input
                placeholder="End Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'endTime'
                )}`}
                name="endTime"
                {...formik.getFieldProps('endTime')}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </div>
          </div>

          {isAdmin() && props.scheduleobj.interpreterID === '' ? (
            <div className="form-group row">
              <div className="col-lg-6">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.INTERPRATOR" />
                </label>

                <select
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'interpreterID'
                  )}`}
                  name="interpreterID"
                  {...formik.getFieldProps('interpreterID')}
                >
                  <option key="" disabled value="">
                    {intl.formatMessage({
                      id: 'DASHBOARD_SELECT_INTERPRATOR'
                    })}
                  </option>

                  {interpratorList &&
                    interpratorList.length > 0 &&
                    interpratorList.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          ) : null}

          {!props.scheduleobj.id ? (
            <div>
              <div className="form-group row mb-4">
                <label className="col-lg-12">
                  {' '}
                  <FormattedMessage id="DASHBOARD.SELECT_DAYS" />
                </label>
              </div>

              <div className="form-group row mb-4">
                <div className="col-lg-12 d-flex">
                  <label className="checkbox checkbox-lg checkbox-single mr-3">
                    <input
                      type="checkbox"
                      checked={initialValues['allchecked']}
                      name="allchecked"
                      onChange={handleAllCheckbox}
                    />
                    <FormattedMessage id="AVAILBILTY_ALL_DAYS" />
                    <span className="ml-2" />
                  </label>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-2 d-flex">
                  {weekDays.map((day) => {
                    return (
                      <label key={day.value} className="checkbox checkbox-lg checkbox-single mr-3">
                        <input
                          type="checkbox"
                          checked={initialValues['day' + day.value]}
                          name={'day' + day.value}
                          onChange={handleCheckbox}
                        />
                        <FormattedMessage id={day.name} />

                        <span className="ml-2" />
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          {props.scheduleobj.id ? (
            <div className="form-group row mb-4">
              <div className="col-lg-6">
                <input
                  type="radio"
                  checked={radiobtn === 0}
                  onChange={() => setRadiobtn(0)}
                  id="current"
                  name="reoccurring"
                  value={0}
                />
                <label htmlFor="current" className="ml-4">
                  {' '}
                  <FormattedMessage id="DASHBOARD.SCHEDULE_CURRENT_DAY" />
                </label>
              </div>
              {props.scheduleobj.reoccurringID && (
                <div className="col-lg-6">
                  <input
                    type="radio"
                    checked={radiobtn === 1}
                    onChange={() => setRadiobtn(1)}
                    id="all"
                    name="reoccurring"
                    value={1}
                  />
                  <label htmlFor="all" className="ml-4">
                    {' '}
                    <FormattedMessage id="DASHBOARD.SCHEDULE_FURTER_DAYS" />
                  </label>
                </div>
              )}{' '}
            </div>
          ) : null}
        </form>
      </PositionedModal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToEvents)(SchedulePopup));
